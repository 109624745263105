<template>
  <v-menu transition="slide-y-transition" offset-y>
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" :block="$vuetify.breakpoint.mobile">
        <span v-html="currentLanguage.flag" />
        <div style="width: 10px" />
        {{ currentLanguage.text }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="lang in languages"
        :key="lang.locale"
        @click="onLanguageChange(lang.locale)"
      >
        <span v-html="lang.flag" />
        <div style="width: 10px" />
        {{ lang.text }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "LanguageMenu",
  computed: {
    currentLanguage() {
      var language = this.languages.filter(
        (language) => language.locale === this.$i18n.locale
      )[0];
      return language;
    },
  },
  data: () => ({
    languages: [
      {
        locale: "en",
        flag: "\uD83C\uDDFA\uD83C\uDDF8",
        text: "English",
      },
      {
        locale: "zh-TW",
        flag: "\uD83C\uDDF9\uD83C\uDDFC",
        text: "正體中文",
      },
    ],
  }),
  mounted() {
    this.locale = this.$i18n.locale;
  },
  methods: {
    decodeEmoji(input) {
      const decoded = input
        .replace(/\\u(....)/g, (match, p1) =>
          String.fromCharCode(parseInt(p1, 16))
        )
        .replace(/\\(\d{3})/g, (match, p1) =>
          String.fromCharCode(parseInt(p1, 8))
        );
      return decodeURI(decoded);
    },
    onLanguageChange(v) {
      this.$i18n.locale = v;
    },
  },
};
</script>