<template>
  <v-menu v-model="openMenu" offset-y transition="slide-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="item.tier"
        v-on="on"
        v-bind="attrs"
        block
        :class="color"
        class="text-h6 font-weight-bold"
        >{{ $t(`theme.tier.${item.tier}`) }}</v-btn
      >
    </template>
    <v-list>
      <v-list-item
        v-for="(theme, idx) in item.themes"
        :key="idx"
        @click="onItemClick(theme.name)"
        >{{ $t(`theme.name.${theme.name}`) }} | {{ $t(`theme.suitable`) }}
        <v-chip
          v-for="tag in theme.tags"
          :key="tag"
          class="mx-1"
          label
          :color="getChipColor(tag)"
          >{{ $t(`theme.tag.${tag}`) }}</v-chip
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "AppBarMenu",
  props: {
    color: {
      type: String,
      required: false,
      default: () => "transparent",
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: () => ({
    openMenu: false,
  }),
  methods: {
    onItemClick(v) {
      this.$emit("change", {
        tier: this.item.tier,
        name: v,
      });
    },
    getChipColor(tag) {
      switch (tag) {
        case "personal":
          return "light-blue darken-2";
        case "campaign":
          return "light-blue darken-4";
        case "product":
          return "amber darken-2";
        case "brand":
          return "amber darken-4";
        case "company":
          return "green darken-2";
        case "service":
          return "green darken-4";
        case "app":
          return "purple darken-2";
        case "dashboard":
          return "purple lighten-2";
      }
    },
  },
};
</script>
