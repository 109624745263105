<template>
  <v-app>
    <v-app-bar app color="teal darken-4" dark>
      <v-row v-if="!$vuetify.breakpoint.mobile" class="align-center">
        <v-col>
          <app-bar-menu
            color="light-blue darken-3"
            :item="getBasicTier[0]"
            @change="onMenuChange"
          />
        </v-col>
        <v-col>
          <app-bar-menu
            color="deep-orange darken-2"
            :item="getAdvTier[0]"
            @change="onMenuChange"
          />
        </v-col>
        <v-col>
          <app-bar-menu
            color="purple darken-2"
            :item="getProTier[0]"
            @change="onMenuChange"
          />
        </v-col>
        <v-spacer></v-spacer>
        <span class="text-h6">{{ title }}</span>
        <v-spacer></v-spacer>
        <language-menu />
      </v-row>
      <v-row v-else class="align-center justify-start" no-gutter>
        <v-btn icon @click="onMobileMenuIconClick"
          ><v-icon>mdi-menu</v-icon></v-btn
        >
        <span class="text-xs-subtitle-2 text-sm-h6 text-truncate"
          style="max-width: 80%">
          {{ title }}
        </span>
      </v-row>
    </v-app-bar>

    <v-main>
      <iframe v-if="selectedTheme" :src="frameSrc" width="100%" height="100%" />
    </v-main>
    <v-navigation-drawer app v-model="isDrawerMenuOpen" width="100%">
      <language-menu />
      <navigation-drawer-menu
        color="light-blue darken-3"
        :item="getBasicTier[0]"
        @change="onDrawerMenuChange"
      />
      <navigation-drawer-menu
        color="deep-orange darken-2"
        :item="getAdvTier[0]"
        @change="onDrawerMenuChange"
      />
      <navigation-drawer-menu
        color="purple darken-2"
        :item="getProTier[0]"
        @change="onDrawerMenuChange"
      />
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import AppBarMenu from "@/components/AppBarMenu.vue";
import NavigationDrawerMenu from "@/components/NavigationDrawerMenu.vue";
import LanguageMenu from "@/components/LanguageMenu.vue";

export default {
  components: {
    AppBarMenu,
    NavigationDrawerMenu,
    LanguageMenu,
  },
  name: "App",

  data: () => ({
    isDrawerMenuOpen: false,
    selectedTheme: null,
    catalog: [
      {
        tier: "basic",
        themes: [
          {
            name: "mk-dashboard",
            tags: ["personal", "app"],
          },
          {
            name: "alpha-ecommerce",
            tags: ["product"],
          },
          {
            name: "parallax",
            tags: ["campaign", "brand", "product", "personal"],
          },
          {
            name: "blog",
            tags: ["campaign", "personal"],
          },
          {
            name: "freelancer",
            tags: ["personal", "company"],
          },
          {
            name: "alpha-saas",
            tags: ["campaign", "product", "service"],
          },
          {
            name: "mk-landing",
            tags: ["campaign", "personal"],
          },
          {
            name: "mk-crypto",
            tags: ["campaign", "product"],
          },
          {
            name: "alpha-construction",
            tags: ["campaign", "brand", "company"],
          },
          {
            name: "alpha-creative",
            tags: ["campaign", "company", "personal", "product"],
          },
        ],
      },
      {
        tier: "advanced",
        themes: [
          {
            name: "mk-portfolio",
            tags: ["personal"],
          },
          {
            name: "photography",
            tags: ["campaign", "personal"],
          },
          {
            name: "themelight",
            tags: ["company", "product", "service"],
          },
          {
            name: "flairo",
            tags: ["brand", "company", "product", "service"],
          },
          {
            name: "zero",
            tags: ["brand", "company", "product", "service"],
          },
        ],
      },
      {
        tier: "pro",
        themes: [
          {
            name: "mk-launcher",
            tags: ["app"],
          },
          {
            name: "vmd",
            tags: ["dashboard", "app"],
          },
          {
            name: "octavia",
            tags: ["dashboard", "app"],
          },
        ],
      },
    ],
  }),
  computed: {
    title() {
      return this.selectedTheme
      ? this.$t(`theme.name.${this.selectedTheme?.name}`)
      : this.$t(`app-bar.title`);
    },
    frameSrc() {
      return `${this.selectedTheme?.tier}-${this.selectedTheme?.name}`;
    },
    getProTier() {
      return this.catalog.filter((v) => v.tier === "pro");
    },
    getAdvTier() {
      return this.catalog.filter((v) => v.tier === "advanced");
    },
    getBasicTier() {
      return this.catalog.filter((v) => v.tier === "basic");
    },
  },
  methods: {
    onMenuChange(src) {
      console.log(src);
      this.selectedTheme = src;
    },
    onDrawerMenuChange(src) {
      this.onMenuChange(src);
      this.isDrawerMenuOpen = false;
    },
    onMobileMenuIconClick() {
      this.isDrawerMenuOpen = !this.isDrawerMenuOpen;
    },
  },

};
</script>
