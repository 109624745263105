<template>
  <v-row>
    <v-col>
      <v-btn block :class="color" class="text-h6 font-weight-bold">{{
        $t(`theme.tier.${item.tier}`)
      }}</v-btn>
      <v-list>
        <v-list-item class="mt-3"
          v-for="(theme, idx) in item.themes"
          :key="idx"
          @click="onItemClick(theme.name)"
        >
          <v-row no-gutters>
            <v-col cols="12">
              {{ $t(`theme.name.${theme.name}`) }}
              | {{ $t(`theme.suitable`) }}
              <v-row no-gutters>
                <v-chip
                  v-for="tag in theme.tags"
                  :key="tag"
                  class="ma-1"
                  label
                  :color="getChipColor(tag)"
                  >{{ $t(`theme.tag.${tag}`) }}</v-chip
                >
              </v-row>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NavigationDrawerMenu",
  props: {
    color: {
      type: String,
      required: false,
      default: () => "transparent",
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: () => ({
    openMenu: false,
  }),
  methods: {
    onItemClick(v) {
      this.$emit("change", {
        tier: this.item.tier,
        name: v,
      });
    },
    getChipColor(tag) {
      switch (tag) {
        case "personal":
          return "light-blue darken-2";
        case "campaign":
          return "light-blue darken-4";
        case "product":
          return "amber darken-2";
        case "brand":
          return "amber darken-4";
        case "company":
          return "green darken-2";
        case "service":
          return "green darken-4";
        case "app":
          return "purple darken-2";
        case "dashboard":
          return "purple lighten-2";
      }
    },
  },
};
</script>
